import React from "react"
import { graphql } from "gatsby"
import { Typography, Container, Grid, Box } from "@material-ui/core"
import _ from "lodash"

import LayoutComponent from "../components/layout.component"
import CompanyCardComponent from "../components/companycard.component"

const Companies = props => {
  return (
    <LayoutComponent>
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid container direction="row">
            <Grid item>
              <Typography variant="h5" component="h2">
                All Companies
              </Typography>
            </Grid>
          </Grid>
          <Box pt={5}>
            <Grid container spacing={3} direction="row">
              {_.map(
                _.slice(props.data.allStrapiCompany.edges, 0, 12),
                ({ node }, i) => {
                  return (
                    <Grid item xs={12} md={3} key={i}>
                      <CompanyCardComponent node={node} />
                    </Grid>
                  )
                }
              )}
            </Grid>
          </Box>
        </Container>
      </Box>
    </LayoutComponent>
  )
}

export default Companies

export const companiesQuery = graphql`
  query companiesQuery {
    allStrapiCompany(sort: { fields: id, order: DESC }) {
      edges {
        node {
          id
          name
          founded_in
          excerpt
          description
          address
          hourly_rate
          type
          website
          office_locations
          location
          slug
          logo {
            publicURL
            name
          }
          cover {
            publicURL
            name
          }
          tags {
            id
            name
            slug
          }
        }
      }
    }
  }
`
