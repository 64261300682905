import React from "react"
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Box,
  Link,
  CardActionArea,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import _ from "lodash"
import { RoomOutlined } from "@material-ui/icons"
import { navigate } from "gatsby"

import theme from "../theme"

const useStyles = makeStyles(theme => ({
  cardHeight: {
    height: "100%",
    borderBottom: `5px solid ${theme.palette.background.apricot}`,
    paddingBottom: theme.spacing(1),
  },
}))

const CompanyCardComponent = props => {
  const classes = useStyles(theme)

  return (
    <Card className={classes.cardHeight}>
      <Link
        component={CardActionArea}
        color="textPrimary"
        underline="none"
        onClick={() => navigate(`/${props.node.slug}`)}
      >
        <CardMedia
          component="img"
          image={props.node.cover.publicURL}
          title={props.node.cover.name}
        />
        <CardContent>
          <Typography gutterBottom variant="body1" component="h3">
            {_.truncate(props.node.name, { length: 100 })}
          </Typography>
          <Typography gutterBottom variant="body1" component="h4">
            {props.node.type}
          </Typography>
          <Box display="flex" flexDirection="row">
            <RoomOutlined fontSize="small" />
            <Typography variant="body2" component="p">
              Address | {props.node.address}
            </Typography>
          </Box>
        </CardContent>
      </Link>
      <Box display="flex" flexWrap="wrap" flexDirection="row" p={1}>
        {_.map(props.node.tags, (tag, i) => {
          return (
            <Box p={1} key={i}>
              <Button
                size="small"
                color="secondary"
                variant="contained"
                key={i}
              >
                {tag.name}
              </Button>
            </Box>
          )
        })}
      </Box>
    </Card>
  )
}

export default CompanyCardComponent
